<template>
  <div class="mb-5 upload_section">
    <b-card class="m-3 ">
      <p class="heading_top">Download The template pack and read the instructions file</p>
      <a href>Download Template Pack</a>
    </b-card>

    <b-card class="m-3">
      <template class="one" v-slot:header>
        <b-card-text class="uploadText">Upload File</b-card-text>
      </template>
       <div class="row d-flex justify-content-end">
          <div class="col-md-8 ">
            <!-- <w-uploader class="mt-1" :dragAndDrop="dragAndDropText" :browse="'Browse'" 
                      :acceptedMediaType="'Icon'" @rawFiles="getFilesUploaded($event)" 
                      :extraDetails="'(supported formats jpg, png, jpeg, gif file upto 2MB )'" 
                      :lblClass="'lbl-font-size'" 
                      :extraDetailClass="'extra-lbl-font-size'"
            /> -->
             <w-uploader class="mt-1" :dragAndDrop="dragAndDropText" :or="'Or'" :browse="'Drag & Drop file'" :browseButton="'Browse File'"
                      :acceptedMediaType="'Icon'" @rawFiles="getFilesUploaded($event)" 
                      :lblClass="'lbl-font-size'" 
                      :extraDetailClass="'extra-lbl-font-size'"
            />
          </div>
        <div class="col-md-4 position-relative">
            <b-button class="upload_btn mr-3">Upload File</b-button>
        </div>
      </div>
    </b-card>

    <b-card class="m-3 upload_previous">
      <p>Files Uploaded Previously</p>
      <w-table :tableData="tabledata" @downloadCSEvent="downloaded($event)"></w-table>
    </b-card>

    <div class="row d-flex justify-content-end">
      <b-button type="submit" class="back_btn" @click="gobackk($event)">Back</b-button>
    </div>
  </div>
</template>

<script>
import ReportsTable from "../../widgets/ReportsTable.vue";
import FileUploader from "../../widgets/FileUploader.vue";
export default {
  components: {
    "w-uploader":FileUploader,
    "w-table": ReportsTable
  },
  data() {
    return {
      tabledata: {
        fields: [
          {
            key: "uploadId",
            label: "Upload Id"
          },
          {
            key: "uploadedBy",
            label: "Uploaded By"
          },
          {
            key: "uploadDate",
            label: "Upload Date & time"
          },
          {
            key: "fileName",
            label: "File Name"
          },
          {
            key: "status",
            label: "Status"
          },
          {
            key: "details",
            label: "Details"
          },
          {
            key: "file_field",
            label: "File"
          }
        ],

        items: [
          {
            uploadId: "1432133",
            uploadedBy: "Suma",
            uploadDate: "12th-dec-2020",
            fileName: "Batch_2.csv",
            status: "Processed",
            details: "Updated stocks of 139 products"
          },
          {
            uploadId: "1432133",
            uploadedBy: "Suma",
            uploadDate: "12th-dec-2020",
            fileName: "Batch_2.csv",
            status: "Processed",
            details: "Updated stocks of 139 products"
          }
        ]
      }
    };
  },
  methods:{
  gobackk(event){
       this.$emit("gobackk");
  }
  }
};
</script>

<style>
  .upload_previous .table tbody td{
    color: #1b1c1d;
    font-family: Roboto;
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;  
  }
</style>
<style scoped>
.heading_top{
  font-family: Roboto;
  font-size: 14px;
  color: #1b1c1d;
}
a {
  text-decoration: underline;
  font-family: Roboto;
  font-size: 14px;
  color: #5fa6e7;
}
.uploadText{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  color: #1b1c1d;
}
.back_btn {
  border-radius: 3px;
  border: solid 1px #5fa6e7;
  padding: 3px 60px;
  background: #5fa6e7;
  font-family: SourceSansPro;
  font-size: 14px;
  margin-right: 31px;
}
.upload_btn{
  border-radius: 3px;
  border: solid 1px #5fa6e7;
  padding: 3px 50px;
  background: #5fa6e7;
  position: absolute;
  bottom: 32px;
  right: 0;
  font-family: SourceSansPro;
  font-size: 14px;
}
.upload_previous p{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  color: #1b1c1d;
}
</style>